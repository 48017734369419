.home .home-main-content {
  .opening-hours {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .opening-hours__hours {
      ul {
        list-style: none;
        margin: 1rem 0;
        padding: 0;
      }
    }
    .opening-hours__welcome {
      h3 {
        margin: 0.5rem 37px;
        color: $cta-text;
      }
      p {
        margin: 0.5rem 37px;
      }
    }
  }
}

@media (min-width: 600px) {
  .home .home-main-content {
    .opening-hours {
      flex-direction: row;
      align-items: flex-start;
      margin-right: $main-margin-desktop;
      margin-left: $main-margin-mobile;
    }
  }
}
@media (min-width: 950px) {
  .home .home-main-content {
    .opening-hours {
      .opening-hours__hours {
        ul {
          border: 3px solid white;
          list-style: none;
          margin: 1rem 0;
          padding: 0.5rem;
        }
      }
      .opening-hours__welcome {
        h3 {
          margin: 0.5rem 37px;
          color: $cta-text;
        }
        p {
          margin: 0.5rem 37px;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .home .home-main-content {
    .opening-hours {
      margin: 6rem $main-margin-desktop-sidebar;
      .opening-hours__hours {
        ul {
          border: 3px solid white;
          list-style: none;
          margin: 1rem 0;
          padding: 0.5rem;
        }
      }
      .opening-hours__welcome {
        h3 {
          margin: 0.5rem 37px;
          color: $cta-text;
          font-size: 2rem;
        }
        p {
          margin: 0.5rem 37px;
          font-size: 1.4rem;
        }
      }
    }
  }
}
