.room-booking {
  &__mobile {
    padding: 0 37px;
    width: 100vw;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #febb00;
    & .button {
      color: #003580;
      border-color: #003580;
      font-size: 2rem;
    }
  }
}

@media (min-width: 600px) {
  .room-booking {
    display: none;
  }
}
