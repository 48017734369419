.contact-form {
  h4 {
    margin: 2rem $main-margin-mobile;
  }

  form {
    display: flex;
    flex-direction: column;
    border: $font-color 1px solid;
    color: $font-color;
    margin: 0 $main-margin-mobile;
    margin-bottom: 3rem;
    padding: 0.5rem;
  }

  .button {
    margin-top: 2rem;
    margin-bottom: 1rem;
    background: none;
    width: 100%;
  }

  &__form-group {
    margin: 1.5rem 0;
    display: flex;
    flex-direction: column;

    p {
      margin-bottom: 1rem;
    }

    span {
      color: $error-color;
      &:first-child {
        color: #f1c40f;
      }
    }
  }

  &__field {
    &--error {
      border: $error-color 2px dashed;
    }
  }
}

@media (min-width: 600px) {
  .contact-form {
    & form,
    & h4 {
      margin-left: $main-margin-desktop;
      margin-right: $main-margin-desktop-sidebar;
    }

    & form {
      width: 50%;
      padding: 3rem 6rem;
    }

    & h4 {
      font-size: 1.6rem;
    }

    &__form-group {
      &:nth-of-type(1),
      &:nth-of-type(2) {
        width: 45%;
      }
      &:nth-of-type(3) {
        width: 60%;
      }
    }
  }
}
