/*------------------------------------*\
    #Typography
\*------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: $font-color;
}

p,
a,
li,
button,
label,
small {
  font-family: Arial, Helvetica, sans-serif;
  color: $font-color;
}

.section-heading {
  font-size: 2rem;
}

.section-sub-heading {
  font-size: 1.6rem;
  color: $cta-text;

  &--white {
    color: #fff;
  }
}
