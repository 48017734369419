.hero.menu-hero {
  background-color: #fff;

  & .hero-img-mobile {
    position: relative;
    height: 35vh;

    & > img {
      height: 35vh;
      width: 100%;
    }

    & .hero-img__overlay {
      height: 35vh;
    }

    & .hero__header {
      position: absolute;
      top: 1rem;
      left: 1rem;
      padding: 0.2rem;
      color: $font-color;
      background-color: rgba(255, 255, 255, 0.15);
      backdrop-filter: blur(2px);
      z-index: 5;

      & h3 {
        margin-bottom: -0.8rem;
      }
      & h1 {
        font-size: 2.3rem;
        letter-spacing: 0.12rem;
        margin: -0.8rem 0 -0.6rem;
      }
      & h4 {
        font-size: 0.6rem;
        color: $dark-text;
        font-weight: 100;
      }
    }
  }
  & .hero-contact {
    padding-top: 6vh;
    &__text p:last-of-type {
      margin-top: 1rem;
    }
  }
  & .hero-contact .hero-contact__text {
    & > * {
      color: $dark-text;
    }
  }

  & .hero-contact .hero-contact__buttons {
    margin-top: 1rem;
    & a {
      border-color: $dark-text;
      color: $dark-text;
    }
  }

  & .hero-contact .hero-contact__location-hint {
    & a,
    & p {
      padding-top: 2vh;
      color: $dark-text;
    }

    & svg {
      margin-top: 0.2rem;
    }
  }
}

@media (min-width: 600px) {
  .hero.menu-hero {
    & > .hero-contact {
      display: none;
    }
    .hero-contact {
      background-color: unset;
      backdrop-filter: unset;
      text-align: unset;
      width: unset;
      position: unset;
      margin: unset;
      padding-top: unset;
      bottom: unset;
      height: unset;
      left: unset;
      transform: unset;

      &__text {
        flex-direction: column;
        p {
          font-size: 1.4rem;
        }
      }
    }
    & .hero-img-large {
      display: flex;
      height: 100vh;
      flex-direction: row-reverse;
      flex-direction: row-reverse;
      justify-content: flex-end;
      &__image {
        position: relative;
      }

      & .hero-contact {
        &__location-hint {
          display: flex;
          margin-top: 6rem;
        }
      }

      &__contact {
        display: flex;
        flex-direction: column;
        margin-top: 4rem;
      }
      &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        z-index: 10;
        margin-left: 2rem;
        & .hero__header > * {
          color: $dark-text;
        }

        & .hero__header {
          @include slide-in;
          & h3 {
            font-size: 2rem;
            margin-bottom: -0.8rem;
          }
          & h1 {
            font-size: 5rem;
            letter-spacing: 0.12rem;
            margin: -2rem 0 -1.6rem;
          }
          & h4 {
            color: $dark-text;
            font-weight: 100;
          }
        }
      }

      &__image {
        height: 100vh;
        width: 55vw;

        & > img {
          height: 100vh;
          width: 100%;
        }
      }
    }
  }
}

@media (min-width: 900px) {
  .hero-contact {
    &__text {
      flex-direction: column;
      p {
        font-size: 36px;
      }
    }
  }
}

