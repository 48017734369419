.events {
  .section-heading {
    margin: 0 $main-margin-mobile;
  }

  .carousel .slide {
    transform: scale(0.9);
    transition: all 0.3s ease;
  }

  li.slide.selected {
    transform: scale(1.1);
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  }
}
