.home-events {
  &.home-section {
    padding-top: 8rem;
  }
  .button {
    margin-top: 2rem;
  }
}

.event__heading,
.event__sub-heading,
.event__description,
.event__link {
  margin: 0 37px;
}
.home-events__event:not(:first-of-type) {
  margin-top: 1.5rem;
}

.event__heading {
  color: $cta-text;
  font-size: 1.4rem;
}
// .event__image {
// }
.event__sub-heading {
  font-size: 1.2rem;
  margin-top: 0.5rem;
}
// .event__description {
// }
// .event__link {
// }

@media (min-width: 600px) {
  .home-events {
    .section-heading {
      margin-bottom: 3rem;
    }
  }
  .home-events__event {
    border: 3px solid white;
    margin-bottom: 4rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 330px;
    padding-right: $sidebar-width;
    background-image: $linear-bg;

    & .event__content > * {
      margin: 0 5px;
    }

    & .event__description {
      font-size: 0.8rem;
    }

    &--reverse {
      flex-direction: row-reverse;
    }
  }

  .event__image {
    height: 100%;
    & > img {
      object-fit: cover;
      height: 100%;
    }
    margin-left: 0px;
  }
}

@media (min-width: 900px) {
  .home-events {
    .section-heading {
      margin-bottom: 3rem;
    }
  }
  .home-events__event {
    border: 3px solid white;
    margin-left: $main-margin-desktop;
    margin-right: $main-margin-desktop;
    margin-bottom: 4rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 330px;
    padding-right: $sidebar-width;
    background-image: $linear-bg;
    transform: translate(-5%);

    &--reverse {
      flex-direction: row-reverse;
    }
  }

  .event__image {
    height: 120%;
    border: 3px solid white;
    & > img {
      object-fit: cover;
      height: 100%;
    }
    margin-left: 37px;
  }
}
