.button {
  border-radius: 3px;
  border: 3px solid white;
  color: #fff;
  font-size: 16px;
  padding: 0.4rem 0.6rem;
  text-decoration: none;
  text-transform: uppercase;
  min-width: 138px;
  text-align: center;
  align-self: center;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }
}

.button-padded {
  display: block;
  padding: 0.8rem 0.4rem;
  max-width: 200px;
}

@mixin cta-button {
  background-color: $cta-text;
  border: none;
  width: auto;
}

.cta-link {
  margin: 0;
  padding: 0;
  border: 0 none;
  position: relative;
  color: $cta-text;
  text-decoration: none;
  &:after {
    font-size: 1.3rem;
    content: ' →';
  }
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background: $cta-text;
    visibility: hidden;
    border-radius: 3px;
    transform: scaleX(0);
    transition: 0.2s ease-in-out;
  }
  &:hover:before,
  &:focus:before {
    visibility: visible;
    transform: scaleX(1);
  }
}
