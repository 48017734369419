.hero.rooms-hero {
  background-color: #fff;

  & .hero-img-mobile {
    position: relative;
    height: 35vh;
    & > img {
      height: 35vh;
    }

    & .hero-img__overlay {
      height: 35vh;
    }

    & .hero__header {
      position: absolute;
      top: 1rem;
      left: 1rem;
      padding: 0.2rem;
      color: $font-color;
      background-color: rgba(255, 255, 255, 0.15);
      backdrop-filter: blur(2px);
      z-index: 5;

      & h3 {
        margin-bottom: -0.8rem;
      }
      & h1 {
        font-size: 2.3rem;
        letter-spacing: 0.12rem;
        margin: -0.8rem 0 -0.6rem;
      }
      & h4 {
        font-size: 0.6rem;
        color: $dark-text;
        font-weight: 100;
      }
    }
  }
  & .hero-contact {
    padding-top: 10vh;
  }
  & .hero-contact .hero-contact__text {
    & > * {
      color: $dark-text;
    }
  }

  & .hero-contact .hero-contact__buttons {
    margin-top: 1rem;
    & a {
      border-color: $dark-text;
      color: $dark-text;
    }
  }

  & .hero-contact .hero-contact__location-hint {
    & a,
    & p {
      padding-top: 6vh;
      color: $dark-text;
    }

    & svg {
      margin-top: 0.2rem;
    }
  }
}

@media (min-width: 600px) {
  .hero.rooms-hero {
    .hero-contact {
      display: none;
    }
    & .hero-img-large {
      margin-left: $main-margin-desktop;
      display: flex;
      height: 100vh;

      &__contact {
        display: flex;
        flex-direction: column;
        margin-top: 4rem;

        & small {
          color: $dark-text;
        }

        & .button {
          color: $dark-text;
          border-color: $dark-text;
          align-self: unset;
        }
      }
      &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 10;
        & .hero__header > * {
          color: $dark-text;
        }

        & .hero__header {
          @include slide-in;
          & h3 {
            font-size: 2rem;
            margin-bottom: -0.8rem;
          }
          & h1 {
            font-size: 4rem;
            letter-spacing: 0.12rem;
            margin: -2rem 0 -1.6rem;
          }
          & h4 {
            color: $dark-text;
            font-weight: 100;
          }
        }
      }

      &__book {
        margin-top: 3rem;
        z-index: 10;
        background-color: #fff;
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.25);

        p {
          color: $dark-text;
          font-style: italic;
        }
        & .booking-container {
          width: 50vw;
          height: 120px;
          display: flex;
          justify-content: center;
          align-items: center;

          & .button {
            background-color: #0071c2;
            border-color: #0071c2;
          }
        }
      }

      &__image {
        height: 100vh;
        width: 55vw;
        position: absolute;
        right: 0;

        & > img {
          height: 100vh;
          width: 100%;
        }
      }
    }
  }
}
