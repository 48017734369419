.home {
  .hero-logo {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
  }
  .hero-img__overlay {
    height: 340px;
  }

  .hero-img-large {
    display: none;
  }

  .hero-img {
    &__bg {
      position: fixed;
      height: 100vh;
      z-index: -1;
    }

    &__mountain {
      position: absolute;
      z-index: 1;
      height: 20%;
      width: 20%;
      bottom: 0%;
      left: 0%;
    }

    &__klubben {
      position: fixed;
      z-index: 1;
      height: 30%;
      bottom: 10%;
    }

    &__huset {
      position: fixed;
      z-index: 2;
      height: 40%;
      bottom: 0%;
      width: 100vw;
    }
  }

  .hero-contact {
    position: absolute;
    bottom: 0;
    z-index: 5;
  }

  .hero-contact__text {
    top: -36px;

    & h2 {
      font-size: 32px;
    }

    & p {
      color: $cta-text;
    }
  }

  @media (min-width: 600px) {
    .hero {
      height: 100vh;
    }
    .hero-logo {
      position: absolute;
      top: 100px;
      left: 52%;
      transform: translateX(-59%);

      & svg {
        min-width: 400px;
        min-height: 300px;
      }
    }

    .hero-logo {
      position: absolute;
      top: 30px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 5;
    }

    .hero-img__overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(51, 60, 90, 0.53);
      z-index: 4;
    }

    .hero-img-large {
      & div:first-of-type::-webkit-scrollbar {
        display: none;
      }
      & > div {
        background-color: #6dbbff;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;
      }
      display: block;
    }

    .hero-img-mobile {
      display: none;
    }

    .hero-image {
      height: 100vh;
      overflow-x: hidden;

      & > img {
        width: 100%;
      }
    }

    .hero-img {
      &__bg {
        position: fixed;
        height: 100vh;
        z-index: -1;
      }

      &__mountain {
        position: absolute;
        z-index: 1;
        height: 20%;
        width: 20%;
        bottom: 0%;
        left: 0%;
      }

      &__klubben {
        position: fixed;
        z-index: 1;
        height: 60%;
        bottom: 10%;
      }

      &__huset {
        position: fixed;
        z-index: 2;
        height: 70%;
        bottom: 0%;
      }
    }

    .hero-contact__location-hint {
      display: none;
    }

    .hero-contact {
      text-align: center;
      width: 100%;
      position: absolute;
      margin: 0;
      padding-top: 2rem;
      bottom: 0;
      height: 250px;
      left: 50%;
      transform: translateX(-50%);
      background-color: rgba(255, 255, 255, 0.15);
      backdrop-filter: blur(2px);
    }

    .hero-contact__text {
      position: relative;
      top: auto;
      display: flex;
      justify-content: center;

      & h2 {
        display: none;
      }
      & p {
        color: #fff;
        font-size: 1.6rem;
        max-width: 40rem;
      }
    }

    .hero-contact__buttons {
      margin-top: 1rem;
      display: flex;
      justify-content: center;

      & a:first-child {
        @include cta-button;
        padding: 1rem 1.5rem;
        font-size: 34px;
      }

      & a:last-child {
        display: none;
      }
    }
  }
  @media (min-width: 900px) {
    .hero {
      height: 100vh;
    }
    .hero-logo {
      position: absolute;
      top: 100px;
      left: 52%;
      transform: translateX(-59%);

      & svg {
        min-width: 400px;
        min-height: 300px;
      }
    }

    .hero-logo {
      position: absolute;
      top: 30px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 5;
    }

    .hero-img__overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(51, 60, 90, 0.53);
      z-index: 4;
    }

    .hero-img-large {
      & > div {
        background-color: #6dbbff;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;
      }
      display: block;
    }

    .hero-img-mobile {
      display: none;
    }

    .hero-image {
      height: 100vh;
      overflow-x: hidden;

      & > img {
        width: 100%;
      }
    }

    .hero-img {
      &__bg {
        position: fixed;
        height: 100vh;
        z-index: -1;
      }

      &__mountain {
        position: absolute;
        z-index: 1;
        height: 20%;
        width: 20%;
        bottom: 0%;
        left: 0%;
      }

      &__klubben {
        position: fixed;
        z-index: 1;
        height: 60%;
        bottom: 10%;
      }

      &__huset {
        position: fixed;
        z-index: 2;
        height: 70%;
        bottom: 0%;
      }
    }

    .hero-contact__location-hint {
      display: none;
    }

    .hero-contact {
      text-align: center;
      width: 100%;
      position: absolute;
      margin: 0;
      padding-top: 2rem;
      bottom: 0;
      height: 250px;
      left: 50%;
      transform: translateX(-50%);
      background-color: rgba(255, 255, 255, 0.15);
      backdrop-filter: blur(2px);
    }

    .hero-contact__text {
      position: relative;
      top: auto;
      display: flex;
      justify-content: center;
      flex-direction: unset;

      & h2 {
        display: none;
      }
      & p {
        color: #fff;
        font-size: 36px;
        max-width: 40rem;
      }
    }

    .hero-contact__buttons {
      margin-top: 1rem;
      display: flex;
      justify-content: center;

      & a:first-child {
        @include cta-button;
        padding: 1rem 1.5rem;
        font-size: 34px;
      }

      & a:last-child {
        display: none;
      }
    }
  }
}
