.loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.loader__logo {
  animation: logo-flash 5s ease;
  animation-iteration-count: infinite;
  margin-bottom: 1rem;
}

.dots {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.dots div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: $cta-text;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.dots div:nth-child(1) {
  left: 8px;
  animation: dots1 0.6s infinite;
}
.dots div:nth-child(2) {
  left: 8px;
  animation: dots2 0.6s infinite;
}
.dots div:nth-child(3) {
  left: 32px;
  animation: dots2 0.6s infinite;
}
.dots div:nth-child(4) {
  left: 56px;
  animation: dots3 0.6s infinite;
}
