.hero.events-hero {
  // background-color: #fff;
  position: relative;

  & .hero-img-mobile {
    position: relative;
    height: 65vh;
    & > img {
      height: 65vh;
    }

    & .hero-img__overlay {
      height: 65vh;
    }

    & .hero__header {
      position: absolute;
      top: 1rem;
      left: 1rem;
      padding: 0.2rem;
      color: $font-color;
      background-color: rgba(255, 255, 255, 0.15);
      backdrop-filter: blur(2px);
      z-index: 5;

      & h3 {
        margin-bottom: -0.8rem;
      }
      & h1 {
        font-size: 1.6rem;
        letter-spacing: 0.12rem;
        margin: -0.4rem 0 -0.3rem;
      }
      & h4 {
        font-size: 0.6rem;
        color: $dark-text;
        font-weight: 100;
      }
    }
  }
  & .hero-contact {
    position: absolute;
    top: 35%;
    z-index: 12;
    &__text p:last-of-type {
      margin-top: 1rem;
    }
  }
  & .hero-contact .hero-contact__text {
    padding: 0.5rem;
    background-color: rgba(184, 184, 184, 0.15);
    backdrop-filter: blur(2px);
    & > * {
      // color: $dark-text;
    }
  }

  & .hero-contact .hero-contact__buttons {
    margin-top: 1rem;
    & a {
      border-color: $dark-text;
      color: $dark-text;
    }
  }

  & .hero-contact .hero-contact__location-hint {
    & a,
    & p {
      padding-top: 2vh;
      color: $dark-text;
    }

    & svg {
      margin-top: 0.2rem;
    }
  }
}

@media (min-width: 600px) {
  .hero.events-hero {
    background-color: #fff;
    & > .hero-contact {
      display: none;
    }
    .hero-contact {
      background-color: unset;
      backdrop-filter: unset;
      text-align: unset;
      width: unset;
      position: unset;
      margin: unset;
      padding-top: unset;
      bottom: unset;
      height: unset;
      left: unset;
      transform: unset;

      .hero-contact__text {
        flex-direction: column;
        background-color: unset;
        backdrop-filter: unset;
        & p {
          font-size: 1.4rem;
          color: $dark-text;
        }
      }
    }
    & .hero-img-large {
      display: flex;
      height: 100vh;
      justify-content: flex-end;

      &__images {
        display: none;
        & > img {
          position: relative;
        }
      }

      & .hero-contact {
        &__location-hint {
          display: none;
        }
      }

      &__contact {
        display: flex;
        flex-direction: column;
        margin-top: 4rem;
      }
      &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        z-index: 10;
        margin-left: 2rem;
        & .hero__header > * {
          color: $dark-text;
        }

        & .hero__header {
          @include slide-in;
          & h3 {
            font-size: 2rem;
            margin-bottom: 0rem;
          }
          & h1 {
            font-size: 4rem;
            letter-spacing: 0.12rem;
            margin: -1rem 0 0rem;
            line-height: 1.1;
          }
          & h4 {
            color: $dark-text;
            font-weight: 100;
          }
        }
      }

      &__image {
        height: 100vh;
        width: 55vw;

        & > img {
          height: 100vh;
          width: 100%;
        }
      }
    }
  }
}

@media (min-width: 900px) {
  .hero.events-hero {
    background-color: #fff;
    & > .hero-contact {
      display: none;
    }
    .hero-contact {
      background-color: unset;
      backdrop-filter: unset;
      text-align: unset;
      width: unset;
      position: unset;
      margin: unset;
      padding-top: unset;
      bottom: unset;
      height: unset;
      left: unset;
      transform: unset;

      & .hero-contact__text {
        flex-direction: column;
        background-color: unset;
        backdrop-filter: unset;
        & p {
          font-size: 1.8rem;
          color: $dark-text;
        }
      }
    }
    & .hero-img-large {
      display: flex;
      height: 100vh;
      margin-left: $main-margin-desktop;
      justify-content: flex-end;
      // flex-direction: row-reverse;
      &__images {
        display: block;
        position: relative;
        height: 100vh;
        width: 50%;
        & > img {
          position: relative;
          height: 35vh;
        }
        & > img:nth-of-type(1) {
          left: -6%;
          top: 25%;
          z-index: 3;
        }
        & > img:nth-of-type(2) {
          left: 50%;
          top: -20%;
          z-index: 1;
        }
        & > img:nth-of-type(3) {
          right: -20%;
          bottom: 26%;
          z-index: 2;
        }
      }

      & .hero-contact {
        &__location-hint {
          display: none;
        }
      }

      &__contact {
        display: flex;
        flex-direction: column;
        margin-top: 4rem;
      }
      &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        z-index: 10;
        margin-left: 2rem;
        & .hero__header > * {
          color: $dark-text;
        }

        & .hero__header {
          @include slide-in;
          & h3 {
            font-size: 2rem;
            margin-bottom: 0rem;
          }
          & h1 {
            font-size: 4rem;
            letter-spacing: 0.12rem;
            margin: -1rem 0 0rem;
            line-height: 1.1;
          }
          & h4 {
            color: $dark-text;
            font-weight: 100;
          }
        }
      }

      &__image {
        height: 100vh;
        width: 55vw;

        & > img {
          height: 100vh;
          width: 100%;
        }
      }
    }
  }
}
