// Container margins
$main-margin-mobile: 37px;
$main-margin-desktop: 9rem;
$main-margin-desktop-sidebar: 300px;

// Margins
$margin-small: 0.5rem;
$margin-medium: 0.8rem;

$sidebar-width: 160px;

// Backgrounds
$linear-bg: linear-gradient($bg-primary, $linear-bg-black);
