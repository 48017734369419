.home-section {
  padding: 1.5rem 0;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  & > h2,
  & > h3,
  & > a,
  & > p {
    margin: 0 $main-margin-mobile;
  }
}

@media (min-width: 600px) {
  .home-section {
    margin-top: 10rem;

    & > h2,
    & > h3,
    & > a,
    & > p {
      margin: 0 $main-margin-desktop;
    }

    &:last-of-type {
      margin-bottom: 6rem;
    }
  }
  .map-mobile {
    display: none;
  }
}
