.menu-item {
  margin-top: 1.5rem;
  &__header {
    display: flex;
    justify-content: space-between;
    font-size: 1.15rem;
    border-bottom: 1px solid $font-color;
    & > * {
      margin: 0;
      padding: 0;
    }
  }

  &__allergens {
    margin-top: 0.7rem;
    font-style: italic;
  }
}

@media (min-width: 600px) {
  .menu-item {
  }
}
