/*------------------------------------*\
    #Color Palette
\*------------------------------------*/
$black-blue: #081214;
$white: #fff;
$pink-orange: #e0907d;
$black: #1f1f1f;
$red: #c0392b;

/*------------------------------------*\
    #Colors
\*------------------------------------*/
$bg-primary: $black-blue;
$cta-text: $pink-orange;
$dark-text: $black-blue;
$font-color: $white;
$nav-bg: $white;
$nav-color: $black-blue;
$linear-bg-black: $black;
$error-color: $red;
