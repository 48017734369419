.overflow-wrapper {
  max-width: 100vw;
}
.home-menu {
  overflow: hidden;
  position: relative;

  & .section-heading {
    margin-bottom: 1.2rem;
  }

  & .button {
    margin-top: 1.8rem;
  }
}
.home-menu__item {
  position: relative;
  display: flex;
  padding: 1.2rem 37px;

  & img {
    height: 100%;
  }
}

.home-menu__item-text {
  margin-left: 1rem;
}

.home-menu__small-circle {
  display: none;
}

.home-menu__big-circle {
  position: absolute;
  width: 140%;
  height: 70%;
  border-radius: 50%;
  transform: translateX(-50%);
  top: 15%;
  background-color: rgba(255, 255, 255, 0.15);
  z-index: -1;
  animation: come-in-left 0.6s ease forwards;
  animation-fill-mode: forwards;
}

@media (min-width: 600px) {
  .home-menu {
    margin: unset;
    padding: 0 $main-margin-desktop !important;
    & .section-heading {
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 6rem;
    }
  }
  .home-menu__big-circle {
    display: none;
  }

  .home-menu__small-circle {
    display: block;
    position: absolute;
    width: 260px;
    height: 260px;
    border-radius: 50%;
    top: -30%;
    background-color: rgba(255, 255, 255, 0.15);
    z-index: -1;
    // animation: come-in-left 0.6s ease forwards;
    // animation-fill-mode: forwards;
    left: 15%;
    // transition: transform 0.4s ease-in-out;
  }

  .home-menu__small-circle--right {
    left: initial;
    right: 5%;
  }

  .home-menu__item {
    margin-bottom: 7rem;
    position: relative;
    transition: transform 1.5s ease;


    &-text {
      margin-left: 0;
      max-width: 25rem;
    }
    & img {
      margin-right: 1.8rem;

      transition: transform 1.5s ease;
      // transform-origin: 50% 50%;
    }
  }

  .home-menu__item--right {
    flex-direction: row-reverse;

    & img {
      margin-left: 1.8rem;
    }

    & .home-menu__item-text {
      position: relative;
      & h3 {
        display: block;
        text-align: right;
      }

      & a {
        position: absolute;
        display: inline-block;
        right: 0;
      }

      & h3 {
        font-weight: bold;
      }
    }
  }
}

@media (min-width: 1200px) {
  .home-menu {
    padding-right: calc(9rem + 160px);
  }
}
