/*------------------------------------*\
  #SIDEBAR
\*------------------------------------*/

.main-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background-color: $nav-bg;
  z-index: 1000;
  // box-shadow: $box-shadow;
  transform: translateX(100vw);
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .sidebar__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & .sidebar__lang {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2rem;
  }

  & .sidebar__lang-btn {
    display: flex;
    // flex-direction: column;
    // justify-content: center;
    color: $nav-color;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 0.8rem;

    & > svg {
      height: 25px;
      width: auto;
      margin-right: 0.2rem;
    }
  }

  &--open {
    transform: translateX(0px);
  }

  & .sidebar__logo-svg {
    fill: $nav-color;
  }

  & .navigation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__item {
      padding: 1em;
      text-transform: uppercase;
      & a {
        text-decoration: none;
        color: $nav-color;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & > svg {
          height: 35px;
          stroke: $nav-color;
          fill: $nav-color;
        }

        &:hover {
          // color: lighten($primary-color, 10%);
        }
      }
      &--active {
        background: $black-blue;
        color: $nav-bg !important;
        padding: 0.2rem;
        & > svg {
          fill: $font-color !important;
          stroke: $font-color !important;
        }
      }
      @media (min-width: 600px) {
        &--active {
          background: $nav-bg;
          color: $black-blue !important;
          padding: 0.2rem;
        }
      }
    }
  }
}

.main-menuBtn {
  width: 60px;
  height: 45px;
  position: fixed;
  right: 2px;
  top: 15px;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  margin-right: 0.5rem;
  z-index: 1010;
  cursor: pointer;
  outline: unset;

  & span {
    display: block;
    position: absolute;
    height: 4px;
    width: 90%;
    background: $font-color;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  & span:nth-child(1) {
    top: 0px;
  }

  & span:nth-child(2),
  & span:nth-child(3) {
    top: 18px;
  }

  & span:nth-child(4) {
    top: 36px;
  }

  & h4 {
    color: $font-color;
    position: absolute;
    top: 46px;
    left: 0;
    text-transform: uppercase;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 0.25em;
    transition: transform 0.25s ease;
    font-size: 0.9em;
  }

  &--open span {
    background: $nav-color;
  }

  &--open h4 {
    transform: scale(0);
    color: $nav-color;
  }

  &--open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

  &--open span:nth-child(2) {
    transform: rotate(45deg);
  }

  &--open span:nth-child(3) {
    transform: rotate(-45deg);
  }

  &--open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
}

@media (min-width: 600px) {
  .main-menuBtn {
    display: none;
  }

  .main-sidebar {
    width: 160px;
    transform: translateX(160px);
    justify-content: space-around;

    &--open {
      transform: translateX(0px);
    }

    & .navigation {
      display: flex;
      flex-direction: column;
      height: 75%;
      justify-content: space-evenly;
      align-items: center;

      &__item {
        padding: 0em;
        text-transform: uppercase;
        & a {
          text-decoration: none;
          color: $font-color;
          font-weight: bold;
          & > svg {
            height: 35px;
            stroke: $font-color;
            fill: $font-color;
          }

          &:hover {
            color: $pink-orange;
          }
        }

        &--active {
          & > svg {
            fill: $nav-color !important;
            stroke: $nav-color !important;
          }
        }
      }
    }

    & .sidebar__lang-btn {
      margin-top: 0.3rem;
      color: $font-color;
      font-size: 12px;
      align-items: center;
      cursor: pointer;
      & > svg {
        width: 15px;
      }

      &:hover {
        color: $pink-orange;
      }
    }
  }

  .sidebar__logo {
    height: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    & a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & a > svg {
      width: 50%;
    }
  }

  .sidebar__content {
    height: 100%;
    width: 100%;
    background-color: $bg-primary;
    color: $font-color;
    justify-content: center;
  }
}
