.events {
  .events__container {
    background: $bg-primary;
    background-image: $linear-bg;
    .info-container {
      margin: 1rem $main-margin-mobile;
      padding-bottom: 3rem;

      & h3 {
        color: $cta-text;
      }
      & p,
      & a {
        display: block;
        margin-top: 1rem;
      }
    }
  }
}

.events-special {
  .events-special__intro {
    margin: 1rem $main-margin-mobile;
  }

  .event__image-image {
    display: none;
  }
  .event__image-thumbnails {
    display: none;
    cursor: pointer;
  }
}

@media (min-width: 600px) {
  .events {
    .section-heading {
    }

    &__container {
      margin-top: 1.8rem;
      border-top: 3px solid white;
      border-bottom: 3px solid white;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 45vh;
      padding-right: $sidebar-width;
      background-image: $linear-bg;

      &:nth-of-type(2),
      &:nth-of-type(3) {
        margin-top: 9rem;
      }

      &:nth-of-type(2) {
        justify-content: flex-end;
        flex-direction: row-reverse;

        & .info-container {
          margin-left: 0;
        }
      }

      &__image {
        height: 60%;
        border: 3px solid white;
        & > img {
          height: 100%;
        }
      }

      & .info-container {
        width: 55%;
        margin-top: 0.8rem;
        h3 {
          font-size: 1.4rem;
        }

        p {
          font-size: 0.8rem;
        }
      }
    }

    .events-events {
      margin-top: 12rem;
      & .events__container {
        & .info-container {
          padding-top: 2rem;
        }
      }
    }

    .events-special {
      margin-top: 12rem;
      & .events-special__intro {
        margin-right: $sidebar-width;
        margin-bottom: 6rem;
        font-size: 1.2rem;
      }

      .events__container {
        border: 3px solid white;
        margin-right: $sidebar-width;
        justify-content: center;
        height: unset;
        position: relative;
        flex-wrap: wrap;
        padding: 0;
        align-items: unset;

        &:nth-of-type(2) {
          flex-direction: unset;
        }

        .event__image {
          width: 60%;
        }

        .info-container {
          margin: unset;
          margin-top: 2rem;
          margin-bottom: 2rem;
          padding-bottom: unset;
        }
      }
    }
  }
}

@media (min-width: 950px) {
  .events {
    .section-heading {
      margin-left: $main-margin-desktop;
    }

    &__container {
      margin-top: 1.8rem;
      border-top: 3px solid white;
      border-bottom: 3px solid white;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 45vh;
      padding-right: $sidebar-width;
      padding-left: $main-margin-desktop;
      background-image: $linear-bg;

      &:nth-of-type(2),
      &:nth-of-type(3) {
        margin-top: 9rem;
      }

      &:nth-of-type(2) {
        justify-content: flex-end;
        flex-direction: row-reverse;

        & .info-container {
          margin-left: 0;
        }
      }

      &__image {
        height: 120%;
        border: 3px solid white;
        & > img {
          height: 100%;
        }
      }

      & .info-container {
        width: 45%;
        margin-top: 0.8rem;
        h3 {
          font-size: 2rem;
        }

        p {
          font-size: 1.2rem;
        }
      }
    }

    .events-events {
      margin-top: 12rem;
      & .events__container {
        & .info-container {
          padding-top: 2rem;
        }
      }
    }

    .events-special {
      margin-top: 12rem;
      & .events-special__intro {

        margin-left: $main-margin-desktop;
        margin-right: $sidebar-width;
        margin-bottom: 6rem;
        font-size: 1.2rem;
      }

      .events__container {
        border: 3px solid white;
        margin-left: $main-margin-desktop;
        margin-right: $sidebar-width;
        justify-content: end;
        height: 60vh;
        position: relative;
        flex-wrap: wrap;
        padding: 0;
        align-items: unset;

        &:nth-of-type(2) {
          flex-direction: unset;
        }

        & .event__image {
          position: relative;
          top: -15%;
          left: -5%;
          height: 60%;
          width: unset;
          margin-left: unset;
          transition: all 0.3s ease-in-out;

          .event__image-image {
            display: block;
            &--slide {
              animation: come-in 0.4s ease forwards;
              animation-fill-mode: forwards;
            }
          }

          .carousel-wrapper {
            display: none;
          }

          &-thumbnails {
            width: 100%;
            display: flex;
            justify-content: space-around;

            & .thumbnail {
              height: 150px;

              &:hover {
                box-shadow: 2px 3px 4px 2px rgba(216, 216, 216, 0.25);
                filter: grayscale(50%);
              }

            }
          }
        }

        .info-container {
          margin: unset;
          margin-top: 2rem;
          padding-bottom: unset;
        }
      }
    }
  }
}
